import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import Gallery from "../components/gallery/gallery";


const VideoLogPage = ({ data, location }) => {
  const content = data.markdownRemark.frontmatter || {};
  const html = data.markdownRemark.html;
  const gallery = data.gallery.edges.map(v => v.node.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/video-log/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <PageHTML dangerouslySetInnerHTML={{ __html: html }} />
          <Gallery data={gallery} forcedType="youtube" />

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default VideoLogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/content/pages/video-log.md/"}) {
      id
      frontmatter {
        title
      }
      html
    }
    gallery: allFile(filter: {absolutePath: {regex: "/content/video-log/"}},  sort: {fields: childrenMarkdownRemark___frontmatter___order, order: DESC}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              order
              youtube
              image{ 
                childImageSharp {
                  thumb: gatsbyImageData(
                    width: 187
                    height: 140
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                  fullscreen: gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;