import React, { useState } from "react";
import { Icon } from '../../ui-components';
import {
  Wrap,
  WrapItem,
  ImageFrame,
  ImageHover,
  Image,
} from './gallery.styled';
import GalleryFullScreen from "./gallery-fullscreen";



const Gallery = ({ data, forcedType = "" }) => {
  const [fullScreenIndex, setFullScreenIndex] = useState(null);
  const fullScreenData = fullScreenIndex !== null ? data[fullScreenIndex] : null;

  const enterFullScreen = (index) => {
    setFullScreenIndex(index);
  }

  const handleNext = (event) => {
    event.preventDefault();
    const next = fullScreenIndex + 1 >= data.length ? 0 : fullScreenIndex + 1;
    setFullScreenIndex(next);
  }

  const handlePrev = (event) => {
    event.preventDefault();
    const prev = fullScreenIndex <= 0 ? data.length - 1 : fullScreenIndex - 1;
    setFullScreenIndex(prev);
  }

  const handleClose = (event) => {
    event.preventDefault();
    setFullScreenIndex(null);
  }

  return (
    <>
      {fullScreenData
        ? (
          <GalleryFullScreen
            data={{
              type: fullScreenData.type,
              image: fullScreenData.image.childImageSharp.fullscreen,
              youtube: fullScreenData.youtube,
            }}
            onNext={handleNext}
            onPrev={handlePrev}
            onClose={handleClose}
            forcedType={forcedType}
          />
        )
        : ''}
      <Wrap>
        {data.map((data, key) => (
          <WrapItem
            key={key}
            onClick={() => enterFullScreen(key)}
          >
            <ImageFrame>
              <Image
                image={data.image.childImageSharp.thumb}
                alt="Gallery Image"
              />
              <ImageHover>
                <Icon
                  name="search-outline"
                  width={24}
                  height={24}
                  noFill
                />
              </ImageHover>
            </ImageFrame>
          </WrapItem>
        ))}
      </Wrap>
    </>
  )
};

export default Gallery;
