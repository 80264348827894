import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";


const cutSize = 4;

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 24px;
  /* gap: 1px; */

  @media ${({ theme }) => theme.device.phone}{
    /* gap: 0; */
  }
`;

export const WrapItem = styled.div`
  position: relative;
  display: block;


  @media ${({ theme }) => theme.device.phone}{
    
  }
`;

const cutSize2 = 4;
const cutSize2Color = 'white'
const btnSize = 32;

export const ImageHover = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${btnSize - cutSize2 * 2}px;
  height: ${btnSize}px;
  margin-top: -${btnSize / 2}px;
  margin-left: -${btnSize / 2 - cutSize}px;
  text-align: center;
  z-index: 1;
  background-color: ${({ theme }) => theme.thirdiary.main};

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  svg{
    margin-top: 3px;
    fill: #fff;
  }
`;

export const ImageFrame = styled.div`
  position: relative;
  width: 186px;
  margin: 1px;
  height: 139px;
  line-height: 0;
  cursor: pointer;

  &:hover{
    ${ImageHover}{
      display: block;
    }
    img{
      filter: brightness(60%);
    }
  }

  @media ${({ theme }) => theme.device.phone}{
    width: 140px;
    height: auto;

  }
`;

export const Image = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  display: block;
  line-height: 0;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-top: ${cutSize2}px solid ${cutSize2Color};
    border-left: ${cutSize2}px solid transparent;
    border-bottom: ${cutSize2}px solid ${cutSize2Color};
    z-index: 100;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    border-top: ${cutSize2}px solid ${cutSize2Color};
    border-right: ${cutSize2}px solid transparent;
    border-bottom: ${cutSize2}px solid ${cutSize2Color};
    z-index: 1;
  }
`;
